// 引入封装的第一层axios接口
import req from '@/utils/req.js'

// 获取课程类型列表
export const getCourseTimeType_api = (params) => {
	return req.get('api/blade-system/dict-biz/dictionary', params)
}

// 获取热门课程列表
export const getCourseList_api = (params) => {
	return req.get('api/resource-manager/client/course/page', params)
}

// 获取课程列表 年级筛选条件
export const getGeneration_api = (params) => {
	return req.get('api/blade-system/dict-biz/dictionary', params)
}
// 获取课程列表 开设场地筛选条件
export const getOpenSite_api = (params) => {
	return req.get('api/blade-system/dept/selectHasCourse', params)
}
// 获取课程列表 预约状态筛选条件
export const getCourseStatus_api = (params) => {
	return req.get('api/blade-system/dict-biz/dictionary', params)
}

// 获取筛选出的课程列表
export const getFilCourseList_api = (params) => {
	return req.get('api/resource-manager/client/course/list', params)
}
// 获取课程详情
export const getCourseInfo_api = (params) => {
	return req.get('api/resource-manager/client/course/detail', params)
}
//课程分类字典
export const getCourseInfo_dic = () => {
	return req.get('api/blade-system/dict-biz/dictionary?code=course_sorts')
}
//课程状态字典
export const getCourseState_dic = () => {
	return req.get('api/blade-system/dict-biz/dictionary?code=course_status')
}
