<template>
  <div class="root">
    <el-image
      style="width: 400px; height: 240px"
      :src="reserveCourseRes.coursePicture"
      fit="cover"
    ></el-image>
    <div class="main">
      <h3>{{$store.state.courseInfo.courseStatus==8?'课程候补已提交！':'恭喜您！课程预约成功'}}</h3>
      <p v-if="reserveCourseRes.courseName">
        课程名称：{{ reserveCourseRes.courseName }}
      </p>
      <p v-if="reserveCourseRes.courseSite">场景：{{ reserveCourseRes.courseSite }}</p>
      <p v-if="reserveCourseRes.courseTime&&$store.state.courseInfo.courseStatus!=8">
        下节课的上课时间：{{ reserveCourseRes.courseTime }}，请提前到场
      </p>
      <p>{{$store.state.courseInfo.courseStatus==8?'可在个人中心——我的预约中查看修改候补信息':'可在个人中心——我的预约中查看修改预约信息'}}
        <span @click="toUser" class="toUser">点击进入我的预约</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  methods: {
    toUser(){
      if(this.$store.state.isLogin){
        this.$router.push("/user/order/myOrder")
      }else{
        this.$router.push("/login?redirect=/user/order/myOrder")
      }
    },
  },
  computed:{
    reserveCourseRes(){
      return this.$store.state.reserveCourseRes
    },
  },
  created() {
  },
};
</script>

<style lang="less" scoped>
@title-color: #0079fe;
.root {
  display: flex;
  .el-image {
    border-radius: 6px;
  }
  .main {
    flex: 1;
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    h3 {
      text-align: center;
      font-weight: 700;
    }
    p{
        line-height: 25px;
    }
    .toUser{
      margin-left:20px;
      color:@title-color;
      user-select: none;
      cursor:pointer;
    }
  }
}
</style>