<template>
  <div class="first">
    <h3>告家长书</h3>
    <p style="text-indent:0">尊敬的家长：</p>
    <p>您好！</p>
    <p>
      为进一步提升学生科学素养和创新能力，武侯区实施了区域跨校场景资源开放。武侯区内五所学校提供各自的教育场景资源，近期将面向全区学生开放并提供免费学习课程。欢迎全区学生参观学习！为确保公益课程顺利推进，现就有关事项明确如下。
    </p>
    <h4>一、课程选择</h4>
    <p>
      本次课程为公益课程，不收取任何费用。学生采用网上预约方式选课。学生需在预约课程前10分钟到达上课地点。
    </p>
    <h4>二、安全事项</h4>
    <p>
      学生在校外行程中的安全问题，由学生家长负责。学生需注意上学、放学安全，建议由家长接送。上课期间，家长不进入校园。<span style="color:#f00"
        >学生须按新冠疫情防控要求接受体温检测，并且全程佩戴口罩，积极落实个人疫情防控措施。</span
      >
    </p>
    <h4>三、遵守纪律</h4>
    <p>
      <strong
        >1.教育孩子在校要注意安全，不得在校园内追逐、打闹等，防止发生意外事故。</strong
      >
    </p>
    <p>2.教育孩子自觉爱护场馆设施设备，损坏设备需照价赔偿。</p>
    <p>
      家长们，孩子是我们的未来，维护学生的安全与生命健康是我们的共同职责。请认真做好孩子的安全教育，履行自己应尽的职责与义务，共同推进公益课程活动顺利实施。谢谢！
    </p>
    <div class="next">
      <el-checkbox v-model="checked"
        >我已阅读并同意遵守《告家长书》内容</el-checkbox
      >
      <button @click="handleNext" class="btn">
        {{
          $store.state.courseInfo.courseStatus == 8 ? "确认候补" : "确认预约"
        }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checked: false, // 是否同意条约
    };
  },
  methods: {
    // 点击确认
    handleNext() {
      if (!this.checked){
        this.$message.error("请阅读并同意相关条约");
      } else {
        this.$confirm('请确认是否预约该课程，每个账号每个月只能退选三次，超过三次将限制选课。并且预约成功后，在开课前24小时内将不能退选。预约成功后，请学生在上课当日，带好通行二维码，没有二维码将无法进入场景，并作考勤缺席处理，累计缺席两次，当前季度内，学生不能再选课！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '重新选择课程',
          type: 'warning'
        }).then(() => {
          this.$emit("changeActive", 1);
        }).catch(() => {      
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
@title-color: #0079fe;
.first {
  padding: 50px;
  line-height: 2em;
  color: #666;
  h3 {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 50px;
    letter-spacing: 5px;
  }
  h4,strong{
    font-weight:700;
    letter-spacing: 3px;
  }
  h4{
    font-size:18px;
  }
  p {
    text-indent: 2em;
    letter-spacing: 3px;
  }
  .next {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .btn {
      background-color: @title-color;
      height: 30px;
      width: 100px;
      margin-top: 10px;
    }
  }
}
</style>