<template>
	<div class="root">
		<div :style="biStyle"></div>
		<div class="containar">
			<el-card class="top">
				<h3>
					{{ courseInfo.courseName }}
				</h3>
				<div class="top-content">
					<div
						style="display: flex; margin-bottom: 20px; padding-right: 20px"
					>
						<el-image
							style="
                width: 400px;
                height: 200px;
                vertical-align: top;
                margin-right: 30px;
              "
							:src="courseInfo.coursePicture"
							fit="cover"
						></el-image>
						<div style="flex: 1; line-height: 2em">
							课程简介：
							<p style="text-indent: 2em">
								{{ courseInfo.courseIntroduction }}
							</p>
						</div>
					</div>
					<p v-if="courseInfo.courseLimitNumber">
						<i class="el-icon-s-custom"> </i> 课程限制人数：{{
							courseInfo.courseLimitNumber
						}}人
					</p>
					<p>
						<i class="el-icon-office-building"> </i> 课程场景：{{
							courseSite.siteName || courseInfo.dept
						}}
					</p>
					<p v-if="courseInfo.courseRoom">
						<i class="el-icon-school"> </i> 上课教室：{{
							courseInfo.courseRoom
						}}
					</p>
					<p v-if="courseInfo.courseLimitGrade">
						<i class="el-icon-star-off"> </i> 适合年级：{{
							courseInfo.courseLimitGrade
						}}年级
					</p>
					<p v-if="courseInfo.courseContactPerson">
						<i class="el-icon-phone"> </i> 联系人：{{
							courseInfo.courseContactPerson
						}}
					</p>
					<p v-if="courseInfo.courseContactPhone">
						<i class="el-icon-mobile-phone"> </i> 联系电话：{{
							courseInfo.courseContactPhone
						}}
					</p>
					<p v-if="courseInfo.courseTeacher">
						<i class="el-icon-user"> </i> 授课教师：{{
							teacherInfo.teacherName
						}}
					</p>
          <p v-if="courseInfoTime.length > 0 &&courseInfoTime[0].timeType">
            <i class="el-icon-document-copy"> </i> 上课类型：{{
              courseInfoTime[0].timeType == 1 ? '专题课' : '体验课'
            }}
          </p>
					<div
						:style="{ lineHeight: '2em', width: '100%' }"
						v-if="
							courseInfoTime.length > 0 &&
								courseInfoTime[0].timeType == '2'
						"
					>
						<i class="el-icon-alarm-clock"> </i> 上课时间：
						<div
							:style="{
								marginLeft: '1vw',
							}"
							v-for="(item, i) in courseInfoTime"
							:key="i"
						>
							<!--第{{ item.round }}轮次第{{ item.session }}课时:-->
							体验课：
							<span style="margin-right: 10px">{{
								item.date
							}}</span>
							<span>{{ item.startTime }} 至 </span>
							<span>{{ item.endTime }}</span>
						</div>
					</div>
					<p
						v-if="
							courseInfoTime.length > 0 &&
								courseInfoTime[0].timeType != '2'
						"
					>
						<i class="el-icon-alarm-clock"> </i> 上课时间：{{
							courseInfoTime[0].time
						}}
					</p>
					<div>
						<i class="el-icon-location-information"> </i>
						上课地点：{{ courseSite.siteAddress }}
					</div>
				</div>
			</el-card>
			<el-card class="main">
				<First v-if="active === 0" @changeActive="changeActive" />
				<Second v-if="active === 1" @changeActive="changeActive" />
			</el-card>
		</div>
	</div>
</template>

<script>
import bc from '@/assets/img/sceneInfoBc.jpg'
import { getCourseInfo_api } from '@/api/course'
import { reserveCourse_api, reservealternate_api } from '@/api/user'
import { sessionGet, sessionSet, sessionClear } from '@/utils/local.js'
import First from './components/first'
import Second from './components/second'
import moment from 'moment'
export default {
	components: {
		First,
		Second,
	},
	data() {
		return {
			active: 0, // 当前步骤
			biStyle: {
				// 导航栏下面的通栏背景图
				height: '280px',
				backgroundImage: 'url(' + bc + ')',
			},
			courseInfo: {},
            courseSite: '', //课程场景
			teacherInfo: '', //授课教师
			courseInfoTime: [], //上课时间
		}
	},
	methods: {
		// 获取课程信息
		async getCourseInfo() {
			let { data, code } = await getCourseInfo_api({
				courseId: this.$route.query.courseId,
			})
			if (code === 200) {
				data.courseInfo.courseLabel = data.courseInfo.courseLabel.split(
					','
				)
				// data.courseDetail = decodeURIComponent(data.courseDetail);
				// 处理时间
				if (
					data.courseInfoTime.length > 0 &&
					data.courseInfoTime[0].timeType == 1
				) {
					data.courseInfoTime[0].time =
						moment(
							new Date(data.courseInfoTime[0].startDay)
						).format('YYYY-MM-DD') +
						' 至 ' +
						moment(new Date(data.courseInfoTime[0].endDay)).format(
							'YYYY-MM-DD'
						) +
						' 每周' +
						data.courseInfoTime[0].dayOfWeek +
						' ' +
						data.courseInfoTime[0].startTime +
						'-' +
						data.courseInfoTime[0].endTime

					this.courseInfoTime = data.courseInfoTime
				} else if (
					data.courseInfoTime.length > 0 &&
					data.courseInfoTime[0].timeType == 2
				) {
					// data.time = JSON.parse(data.recentRound).courseTimeList
					// data.time.forEach((item, i) => {
					// 	item.date = moment(item.date).format('yyyy-MM-DD')
					// })
					this.courseInfoTime = data.courseInfoTime
				}
				this.courseInfoTime = data.courseInfoTime
				this.courseSite = data.courseSite
				this.courseInfo = data.courseInfo
				this.teacherInfo = data.teacherInfo
				this.$store.commit('updateCourseInfo', data.courseInfo)
			}
		},
		// 改变步骤
		// 确认预约后获取预约详情
		async changeActive(val) {
			let submit
			if (this.courseInfo.courseStatus == 8) {
				// 判断是候补还是预约
				submit = reservealternate_api
			} else {
				submit = reserveCourse_api
			}
			let { code, data, msg } = await submit({
				reserveCourseJson: JSON.stringify({
					courseId: this.courseInfo.id,
					studentId: this.$store.state.userInfo.id,
					tenantId: this.$tenantId,
				}),
			})
			if (code === 200) {
				this.$message({
					type: 'success',
					message: msg,
				})
				this.$store.commit('updateReserveCourseRes', data)
				this.active = val
				sessionSet('courseAddActive', val)
			} else {
				this.$message.error(msg)
			}
		},
	},
	created() {
		if (
			!(
				this.$store.state.userInfo.roleName === 'student' ||
				this.$store.state.userInfo.roleName === '学生'
			)
		) {
			this.$router.go(-1)
			this.$message.error('课程只有学生账号可以预约')
		}
		this.active = sessionGet('courseAddActive') || 0
		this.getCourseInfo()
	},
	beforeDestroy() {
		sessionClear('courseAddActive')
	},
}
</script>

<style lang="less" scoped>
@btn-color: #f66866;
@title-color: #0079fe;
.containar {
	margin-top: -200px;
	.top {
		margin: 100px 50px 50px;
		padding: 10px 0 0 10px;
		h3 {
			font-size: 24px;
			font-weight: 700;
			color: @btn-color;
			margin-bottom: 10px;
			span {
				margin-left: 20px;
				padding: 2px 10px;
				font-size: 14px;
				border-radius: 15px;
				color: #fff;
				background-color: @title-color;
			}
		}
		.top-content {
			line-height: 2em;
			font-size: 14px;
			p {
				display: inline-block;
				min-width: 50%;
			}
		}
	}
	.main {
		margin-bottom: 50px;
	}
}
</style>
